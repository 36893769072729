.client-portal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.parent-portal {
  padding: 1rem;
  justify-content: center;
}

.portal-section {
  text-align: center;
  position: relative;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px; /* Reduced gap between image and text */
  padding: 1rem;
}

.portal-icon {
  width: 80px; /* Slightly smaller icon */
  height: 80px;
  transition: transform 0.3s ease;
}

.portal-icon:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

.text-content h2 {
  font-size: 24px; /* Adjusted font size */
  margin-bottom: 8px; /* Slightly reduced margin */
}

.text-content-cards p {
  font-size: 14px; /* Slightly smaller font size */
  max-width: 200px;
}

.tech-login-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.tech-login-list li {
  list-style-type: none; /* Removes bullets */
}
.text-content p,
h2 {
  font-size: 24px; /* Slightly smaller font size */
}

.text-content h2 {
  font-size: xx-large; /* Slightly smaller font size */
  margin-top: 0;
}

.portal-btn {
  font-size: 14px; /* Smaller button text */
  padding: 10px 20px; /* Reduced padding for buttons */
  background-color: #1e90ff; /* Vibrant accent color */
  color: #fff;
  border: none;
  border-radius: 20px; /* Slightly smaller border radius */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.portal-btn:hover {
  background-color: #3ebfff;
  transform: translateY(-2px); /* Adds a subtle lift on hover */
}

.downloads-list {
  padding: 0;
  margin: 20px 0 0 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 200px;
  gap: 2rem;
  list-style-type: none;
}

.text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box-tab {
  border-radius: 12px;
  background-color: lightblue;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
}

.downloads-list li {
  max-width: 200px;
}

.downloads-list a {
  text-decoration: none;
  transition: color 0.3s ease;
}

.downloads-list a:hover {
  color: #3ebfff; /* Link hover color */
}

@media only screen and (max-width: 833px) {
  .downloads-list {
    height: fit-content;
    align-items: center;
  }

  .height li {
    height: fit-content;
  }

  .tech-login-list {
    align-items: center;
    padding: 0;
    justify-content: center;
  }
}
