.mission-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 4rem;
  padding-top: 1rem;

}

.mission-statement {
  border-radius: 12px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mission-statement h1 {
  font-size: 2.4em;
  margin-bottom: 15px;
  text-align: center;
}

.mission-statement p {
  font-size: 1.2em;
  line-height: 1.6;
  max-width: 700px;
  text-align: center;
  font-weight: bold;
}

.quote-author {
  display: block;
  font-style: italic;
  color: #555;
  margin-top: 15px;
}

.company-info {
  background: lightskyblue;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.company-info h2 {
  font-size: 2em;
  margin-bottom: 15px;
  color: #000;
}

.company-info p {
  font-size: 1.1em;
  line-height: 1.6;
}

.team {
  border-radius: 12px;
  cursor: default;
}

.team h2 {
  font-size: 2em;
  margin-bottom: 15px;
  color: #000;
}

.team-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.team-member {
  width: 200px;
  padding: 20px;
  border-left: 4px solid #007bff;
  border-radius: 8px;
  background: lightblue;
  margin-bottom: 20px;
  transition: background 0.3s ease, border-left 0.3s ease;
}

.team-member h3 {
  margin: 0 0 10px;
  font-size: 1.4em;
}

.team-member p {
  font-size: 1em;
  color: #666;
}

.team-member:hover {
  background: #e0e0e0;
  border-left-color: #0056b3;
}

@media only screen and (max-width: 833px) {
  .team-member {
    width: 100%;
    margin-bottom: 12px;
  }
  .mission-container {
    padding: 1rem;
  }
  .team-list {
    gap: 0;
  }
}
.faq-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}
.faq-container h1 {
  text-align: center;
  font-size: xxx-large;
}
.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-weight: bold;
}

.faq-answer {
  padding: 10px 0;
  color: #333;
}

.faq-toggle-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 833px) {
  .faq-container {
    padding: 0;
  }
}
