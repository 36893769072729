@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  /* background: linear-gradient(
    to right,
    #4a92d8,
    #74b5ee,
    
    #ffffff,
    #ffffff,
    #ffffff
  ); */

  background-color: #f0f4f9; /* Slightly warmer light gray */
}
h1,
h2,
h3,
p {
  font-weight: 100 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Adjust the size of tooltip text */
.MuiTooltip-tooltip {
  font-size: 16px !important; /* Use !important to override default styles */
  background-color: #333; /* Tooltip background color */
  color: #fff; /* Tooltip text color */
  padding: 10px; /* Padding inside the tooltip */
  border-radius: 4px; /* Border radius */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Shadow around the tooltip */
}

/* Adjust the color of the tooltip arrow */
.MuiTooltip-arrow {
  color: #333; /* Tooltip arrow color */
}
