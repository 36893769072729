.hero-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
  width: 100%;
  height: 100vh;
  align-items: center;
}

.dark-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(31, 30, 30, 0.47);
  z-index: -1;
}

.img-conatiner-hero {
  width: 40%;
}
.hero-txt-container {
  max-width: 40%;
}

.hero-txt-container-carousel {
  color: white;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: xx-large;
  text-align: center;
}

.hero-txt-container p {
  line-height: 4vh;
  font-weight: bolder !important;
  color: black;
}

.hero-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-container {
  padding-bottom: 4rem;
  padding-top: 0rem;
  /* background-image: url("../assets/hero-img.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
}

.home-header {
  text-align: center;
  margin-bottom: 40px;
}

.home-title {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 3rem;
}

.home-title-wp {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 0rem;
  margin-top: 0;
}

.home-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.home-box {
  width: 310px;
  flex: 1 1;
  padding: 1rem;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background: lightblue;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: x-small !important;
  text-overflow: clip;
}

.home-box ul {
  padding: 0;
  list-style: none;
}

.home-section-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #2c3e50;
}

.home-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.home-list li {
  margin-bottom: 10px;
  font-size: 1.1rem;
  color: #34495e;
}

.home-text {
  font-size: 1rem;
  color: #34495e;
  line-height: 1.6;
  font-weight: bold;
  margin-top: 0;
}

.home-image {
  display: block;
  margin: 0 0 20px;
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;
}

.section-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}
.home-contact {
  font-size: 1.3rem;
  color: #2c3e50;
}

.c-width {
  width: 100%;
  justify-content: center;
  display: flex;
  position: relative;
  z-index: 0;
}

.sqs-block-content {
  width: 60%;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem;
}

.book-btn {
  width: fit-content;
  font-size: 17px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
  line-height: 49px;
  text-align: left;
  white-space: nowrap;
  overflow: visible;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  min-height: 0px;
  height: auto;
  border-radius: 7px;
  background: rgb(236, 131, 56);
  pointer-events: auto;
  filter: brightness(100%);
  border-width: 0px;
  border-style: none;
  border-color: rgb(255, 255, 255);
}

.home-box img,
.home-box svg {
  width: 100%;
  object-fit: contain;
  height: 7%;
  padding-top: 1rem;
}

.service-card {
  background-color: #2c3e50;
  color: white;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  width: 200px; /* Smaller rectangular size */
  height: auto; /* Adjust height based on content */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  transition: transform 0.2s;
  height: 3rem;
  cursor: default;
}

.d-flex {
  height: auto !important;
  margin-top: 3rem;
}

.service-card:hover {
  transform: scale(1.05);
}

.service-card p {
  margin: 0;
  padding: 0 5px;
  font-size: 0.9em;
  font-weight: bold;
  text-align: center;
  color: white;
}
.quicklinks-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.quicklinks-container div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem;
  padding-bottom: 0;
  gap: 1rem;
}

.quicklink-button {
  background: transparent;
  border: 1px solid black;
  border-radius: 12px;
  padding: 1rem;
  font-size: 1em;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0);
}

.quicklink-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.9);
  border-color: transparent;
}

a {
  text-decoration: none;
  width: fit-content;
}
.video-player {
  position: absolute;
  top: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/***************************************** media *******************************************/

@media only screen and (max-width: 833px) {
  .hero-container {
    flex-direction: column;
  }
  .hero-txt-container {
    max-width: 100%;
  }

  .quicklinks-container div {
    padding: 0;
    padding-top: 0.5rem;
  }

  .quicklinks-container {
    margin-bottom: 0;
  }

  .hero-txt-container-carousel h1 {
    font-size: larger;
  }
  .hero-txt-container-carousel {
    font-size: medium !important;
    padding: 0;
    max-width: 90%;
  }
  .home-container {
    padding: 1rem;
  }
  .home-box {
    width: 85%;
  }
  .section-container {
    gap: 0;
  }
  .home-section {
    margin-bottom: 1rem;
    width: 100%;
  }
  .home-title {
    padding: 1rem;
    margin-bottom: 1rem;
  }
  .services-container {
    flex-wrap: nowrap;
    overflow: scroll;
    gap: 12px;
    justify-content: flex-start;
  }

  .services-container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .services-container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .service-card {
    margin: 0;
    width: fit-content;
    height: 8rem;
  }
  .service-card p {
    min-width: 170px;
  }
  .quicklink-button {
    width: fit-content;
    height: fit-content;
  }
  .img-conatiner-hero {
    width: 100%;
  }

  .hero-txt-container p {
    text-align: justify;
    padding: 1rem;
  }
  .hero-txt-container {
    text-align: center;
  }
}
