.headerContainer {
  display: flex;
  height: 6rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 6;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2); /* White with transparency */

}

.tab {
  cursor: pointer;
  width: fit-content;
}
.tab a {
  text-decoration: none;
  color: inherit;
}
.noScroll {
  overflow: hidden;
}

.close-btn {
  /* Styles for the close button */
  background: none;
  border: none;
  font-size: xx-large;
  color: black; /* Adjust color as needed */
  cursor: pointer;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 43%;
  top: 1rem;
  /* Additional styles like padding, margin, etc. can be added based on design requirements */
}

.headerOptions {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.navbar-options {
  display: flex;
  flex-direction: column;
}

.navbar-options a {
  text-decoration: none;
  color: inherit;
}

.logoContainer {
  max-width: 400px;
}

.logoImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.headerMobile {
  display: none;
}

.tabUnderlined {
  border-bottom: 2px solid white;
}

.conatiner-projects {
  text-align: center;
}

.headerContainer {
  transition: background-color 0.3s ease;
}

.headerContainer.scrolled {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .5);
}


/***************************************** Media Qurey ***********************************/

@media only screen and (max-width: 1244px) {
  .headerContainer {
    display: none;
  }

  .headerMobile {
    display: flex;
    width: 100%;
    position: sticky;
    background: transparent;
    z-index: 5;
    top: 0px;
    background: linear-gradient(
      to right,
      #74b5ee,
      #4a92d8,
      #74b5ee,
      #ffffff
    ); /* Gradient from blue to white */
  }
  .headerMobileOptions {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .menuIconContainer {
    height: 40px;
    width: 40px;
  }
  .menuIconImg {
    width: 100%;
    height: 100%;
  }

  .optionsMenuBox {
    position: absolute;
    z-index: 5;
    right: -40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: black;
    background: white;
    height: 100dvh;
    gap: 1rem;
    padding-left: 1rem;
    width: 100%;
    overflow: auto;
}

  .pMobile {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
  }
  .logoContainer {
    padding-top: 0.5rem;
    width: 250px;
  }
  .tab {
    font-size: x-large;
  }
}
