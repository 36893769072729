.project-photos {
  text-align: center;
  color: white;
  /* background: linear-gradient(167deg, rgba(36, 43, 60, 1), rgba(122, 126, 137, 1) 49.48%, rgba(22, 28, 42, 1) 100%); */
}

.header {
  padding: 20px;
  background-color: #457b9d;
}

.header h1 {
  margin: 0;
  font-size: 2.5em;
}

.header p {
  margin: 0;
  font-size: 1.2em;
}

.nav {
  display: flex;
  justify-content: center;
  background-color: #1d3557;
  padding: 10px 0;
}

.nav a {
  color: white;
  text-decoration: none;
  margin: 0 15px;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}

.nav a:hover,
.nav a.active {
  background-color: #457b9d;
  border-radius: 5px;
}

.conatiner-projects h1 {
  margin-bottom: 0 !important;
}

.photos-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 5rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.photo-card {
  background-color: #1976d2;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  max-width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.card-description-photos {
  height: 20%;
  display: flex;
  align-items: center;
}

.img-photo-container {
  width: 100%;
  height: 80%;
}

.photo-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.photo-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo-card h2 {
  margin: 0;
  padding: 10px;
  font-size: 1.2em;
  color: white;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: #1d3557;
  padding: 20px;
  width: 80%;
  max-height: 90%;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: white;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 7;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #457b9d;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.5em;
  color: white;
}

.modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.modal-image {
  width: 100%;
  height: 80vh;
  object-fit: contain;
}

.nav-button {
  background-color: transparent;
  border: none;
  width: 50px;
  color: white;
  cursor: pointer;
  transition: color 0.3s ease;
}

.nav-button:hover {
  color: #a8dadc;
}

.close-button {
  background-color: transparent;
  border: none;
  font-size: 1.5em;
  color: white;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #a8dadc;
}
.load-more-btn {
  background-color: #1976d2;
  color: white;
  border: none;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 16px;
  margin-top: 20px;
  margin-bottom: 5rem;
  font-weight: 500;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.load-more-btn:hover {
  background-color: darkblue;
}

@media only screen and (max-width: 833px) {
  .photos-flex {
    padding: 1rem;
    padding-top: 2rem;
  }
  .photo-card {
    max-width: 100%;
  }
  .photo-card h2 {
    font-size: medium;
  }
  .modal-image {
    width: 70%;
    height: 100%;
  }
  .modal {
    width: 88%;
  }

  .img-photo-container{
    height: 90%;
  }
}
