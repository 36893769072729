.tab-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
  height: 20px;
}

.tab-btn {
  background-color: transparent;
  font-weight: 500;
  font-size: larger;
  cursor: pointer;
  color: black;
  transition: transform 0.3s ease, background-color 0.3s ease;
  width: fit-content;
  font-size: medium;
  text-wrap-mode: nowrap;
}

.tab-btn:hover {
  border-bottom: 2px solid black;
  transform: translateY(-5px);
}

a {
  text-decoration: none;
  color: inherit;
}

/***************************************** media *******************************************/

@media only screen and (max-width: 833px) {
  .tab-container {
    display: none;
  }
}
