/* sitemap.css */
.sitemap-container {
  max-width: 100%;
}

/* Styling specific to h1 in sitemap */
.sitemap-container h1 {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

/* Styling specific to h2 in sitemap */
.sitemap-container h2 {
  font-size: 1.5rem;
  color: #333;
  margin-top: 20px;
}

/* Styling for links within the sitemap */
.sitemap-container a {
  color: #007bff;
  text-decoration: none;
  font-size: 1rem;
}

.sitemap-container a:hover {
  text-decoration: underline;
}

/* Remove bullets and spacing between items */
.sitemap-container ul {
  list-style-type: none;
  padding: 1rem;
}

.sitemap-container ul li {
  margin-bottom: 10px;
}

/* Nested lists styling */
.sitemap-container ul ul {
  padding-left: 20px;
  margin-top: 1rem;
}

.banner-sitemap {
  width: 100%;
  height: 300px;
  margin-left: 0 !important;
  padding: 0;
}

.banner-sitemap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
