.footer-container {
  padding: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 5rem;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 1rem;
}

.footer-section {
  flex: 1;
  margin: 0 1rem;
}

.footer-section h4 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  border-bottom: 2px solid #444;
  padding-bottom: 0.5rem;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-links a {
  text-decoration: none;
  margin: 0.5rem 0;
  transition: color 0.3s;
}

.footer-bottom {
  text-align: center;
  padding-top: 1rem;
  border-top: 1px solid #444;
  width: 100%;
  max-width: 1200px;
}

.footer-bottom p {
  margin: 0;
  font-size: 0.9rem;
}

.logo-container {
  display: flex;
}

.footer-logo {
  max-width: 200px;
  height: auto;
}

@media only screen and (max-width: 833px) {
  .footer-content {
    flex-direction: column;
  }
  .footer-container {
    margin-top: 1rem;
  }
}
